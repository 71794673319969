import { helpers } from '@vuelidate/validators';
import type { ValidatorFn, ValidationArgs, ValidationRule } from '@vuelidate/core';
import { differenceInCalendarDays } from 'date-fns';
import { isRef, Ref } from 'vue';

export interface FilterDatesData {
  from: Date;
  to: Date;
}

// eslint-disable-next-line max-len
export type FilterDatesValidationArgs = Partial<Record<keyof FilterDatesData, ValidationRule | ValidationArgs>>;

const validateDate: ValidatorFn<Date, FilterDatesData | Ref<FilterDatesData>> = (_, dates) => {
  const compare = isRef(dates) ? dates.value : dates;
  const daysDiff = differenceInCalendarDays(compare.to, compare.from);
  return daysDiff >= 0;
};

const filterDatesValidations: FilterDatesValidationArgs = {
  from: {
    isDateLessOrEqual: helpers.withMessage('Please select date that is less or equal to date', validateDate),
  },
  to: {
    isDateLessOrEqual: helpers.withMessage('Please select date that is greater or equal from date', validateDate),
  },
};

export default filterDatesValidations;
