import {
  ApolloClient,
} from '@apollo/client/core';
import type { NormalizedCacheObject } from '@apollo/client/core';
import createDefaultClient from './defaultClient';

export type ApolloClients = {
  [k: string]: ApolloClient<NormalizedCacheObject>;
};

export function getAllClients(): ApolloClients {
  const defaultClient = createDefaultClient();
  let clients = {};

  if (defaultClient) {
    clients = {
      default: defaultClient,
    };
  }

  return clients;
}
