import {
  AuditHistoryIcon,
  DashboardIcon, GlobalDefinitionIcon,
  JurisdictionManagementIcon, LegendIcon,
  LocalDefinitionIcon, ManageUpdateCycleIcon,
  StatusIcon,
} from '@/assets/icons/';
import { Products } from '@/config/enums';
import { Paths, Views } from '@/router/enums';
import { RouteRecordRaw } from 'vue-router';

const navigatorRoutes: Array<RouteRecordRaw> = [
  {
    path: `/${Paths.NAVIGATOR}`,
    name: Products.NAVIGATOR,
    component: () => import(/* webpackChunkName: "navigator" */ '@/products/navigator/NavigatorLayout.vue'),
    redirect: () => ({ name: `${Products.NAVIGATOR}:${Views.DASHBOARD}` }),
    meta: {
      requiredPermissions: ['navigator-collaboration'],
    },
    children: [
      {
        path: Paths.DASHBOARD,
        name: `${Products.NAVIGATOR}:${Views.DASHBOARD}`,
        component: () => import(/* webpackChunkName: "dashboard-view" */ '@/views/dashboard/Dashboard.vue'),
        meta: {
          requiredPermissions: ['navigator-collaboration'],
          icon: DashboardIcon,
        },
      },
      {
        path: `${Paths.DOCUMENT}/:documentCycleId`,
        name: `${Products.NAVIGATOR}:${Views.DOCUMENT}`,
        component: () => import(/* webpackChunkName: "document-view" */ '@/views/document/Document.vue'),
        meta: {
          requiredPermissions: ['navigator-collaboration'],
        },
        props: (route) => ({ documentCycleId: route.params.documentCycleId }),
      },
      {
        path: `${Paths.DOCUMENT}/:documentCycleId/${Paths.VERSION}/:versionId`,
        name: `${Products.NAVIGATOR}:${Views.DOCUMENT_VERSION}`,
        component: () => import(/* webpackChunkName: "document-view" */ '@/views/document/Document.vue'),
        meta: {
          requiredPermissions: ['navigator-collaboration'],
        },
        props: (route) => ({
          documentCycleId: route.params.documentCycleId,
          versionId: route.params.versionId,
        }),
      },
      {
        path: Paths.UPDATE_CYCLE,
        name: `${Products.NAVIGATOR}:${Views.UPDATE_CYCLE}`,
        redirect: () => ({ name: `${Products.NAVIGATOR}:${Views.UPDATE_CYCLE_MANAGE}` }),
        component: () => import(/* webpackChunkName: "update-cycle-view" */'@/views/update-cycle/UpdateCycle.vue'),
        meta: {
          requiredPermissions: ['navigator-collaboration-owner'],
        },
        children: [
          {
            path: Paths.UPDATE_CYCLE_MANAGE,
            name: `${Products.NAVIGATOR}:${Views.UPDATE_CYCLE_MANAGE}`,
            component: () => import(/* webpackChunkName: "manage-update-cycle-view" */'@/views/update-cycle/ManageUpdateCycle.vue'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
              icon: ManageUpdateCycleIcon,
            },
          },
          {
            path: `${Paths.UPDATE_CYCLE_PUBLISH}/:cycleId`,
            name: `${Products.NAVIGATOR}:${Views.UPDATE_CYCLE_PUBLISH}`,
            component: () => import(/* webpackChunkName: "publish-cycle-documents-view" */ '@/views/update-cycle/PublishCycleDocuments.vue'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
            },
          },
          {
            path: Paths.UPDATE_CYCLE_CREATE,
            name: `${Products.NAVIGATOR}:${Views.UPDATE_CYCLE_CREATE}`,
            component: () => import(/* webpackChunkName: "create-cycle-view" */'@/views/update-cycle/CreateCycle.vue'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
            },
          },
        ],
      },
      {
        path: Paths.LOCAL_DEFINITIONS,
        name: `${Products.NAVIGATOR}:${Views.LOCAL_DEFINITIONS}`,
        redirect: () => ({ name: `${Products.NAVIGATOR}:${Views.LOCAL_DEFINITIONS_LIST}` }),
        component: () => import(/* webpackChunkName: "local-definitions-view" */'@/views/local-definitions/LocalDefinitions.vue'),
        meta: {
          requiredPermissions: ['navigator-collaboration-owner'],
          icon: LocalDefinitionIcon,
        },
        children: [
          {
            path: Paths.LOCAL_DEFINITIONS_LIST,
            name: `${Products.NAVIGATOR}:${Views.LOCAL_DEFINITIONS_LIST}`,
            component: () => import(/* webpackChunkName: "list-local-definitions-view" */'@/views/local-definitions/ListLocalDefinitions.vue'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
            },
          },
          {
            path: Paths.LOCAL_DEFINITIONS_CREATE,
            name: `${Products.NAVIGATOR}:${Views.LOCAL_DEFINITIONS_CREATE}`,
            component: () => import(/* webpackChunkName: "create-local-definitions-view" */'@/views/local-definitions/CreateLocalDefinition.vue'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
            },
          },
        ],
      },
      {
        path: Paths.GLOBAL_DEFINITIONS,
        name: `${Products.NAVIGATOR}:${Views.GLOBAL_DEFINITIONS}`,
        redirect: () => ({ name: `${Products.NAVIGATOR}:${Views.GLOBAL_DEFINITIONS_LIST}` }),
        component: () => import(/* webpackChunkName: "global-definitions-view" */'@/views/global-definitions/GlobalDefinitions.vue'),
        meta: {
          requiredPermissions: ['navigator-collaboration-owner'],
          icon: GlobalDefinitionIcon,
        },
        children: [
          {
            path: Paths.GLOBAL_DEFINITIONS_LIST,
            name: `${Products.NAVIGATOR}:${Views.GLOBAL_DEFINITIONS_LIST}`,
            component: () => import(/* webpackChunkName: "list-global-definitions-view" */'@/views/global-definitions/ListGlobalDefinitions.vue'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
            },
          },
          {
            path: Paths.GLOBAL_DEFINITIONS_CREATE,
            name: `${Products.NAVIGATOR}:${Views.GLOBAL_DEFINITIONS_CREATE}`,
            component: () => import(/* webpackChunkName: "create-global-definitions-view" */'@/views/global-definitions/CreateGlobalDefinition.vue'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
            },
          },
          {
            path: `${Paths.GLOBAL_DEFINITIONS_EDIT}/:definitionId`,
            name: `${Products.NAVIGATOR}:${Views.GLOBAL_DEFINITIONS_EDIT}`,
            component: () => import(/* webpackChunkName: "edit-global-definitions-view" */'@/views/global-definitions/EditGlobalDefinition.vue'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
            },
          },
        ],
      },
      {
        path: Paths.LEGENDS,
        name: `${Products.NAVIGATOR}:${Views.LEGENDS}`,
        redirect: () => ({ name: `${Products.NAVIGATOR}:${Views.LEGENDS_LIST}` }),
        component: () => import(/* webpackChunkName: "legends-view" */'@/views/legends/Legends.vue'),
        meta: {
          requiredPermissions: ['navigator-collaboration-owner'],
          icon: LegendIcon,
        },
        children: [
          {
            path: Paths.LEGENDS_LIST,
            name: `${Products.NAVIGATOR}:${Views.LEGENDS_LIST}`,
            component: () => import(/* webpackChunkName: "list-legends-view" */'@/views/legends/ListLegends.vue'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
            },
          },
          {
            path: Paths.LEGENDS_CREATE,
            name: `${Products.NAVIGATOR}:${Views.LEGENDS_CREATE}`,
            component: () => import(/* webpackChunkName: "create-legend-view" */'@/views/legends/CreateLegend.vue'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
            },
          },
        ],
      },
      {
        path: Paths.STATUS_TABLE,
        name: `${Products.NAVIGATOR}:${Views.STATUS_TABLE}`,
        component: () => import(/* webpackChunkName: "status-table-view" */'@/views/status/StatusTable.vue'),
        meta: {
          requiredPermissions: ['navigator-collaboration-owner'],
          icon: StatusIcon,
        },
        redirect: {
          name: `${Products.NAVIGATOR}:${Views.STATUS_TABLE_MANAGE}`,
        },
        children: [
          {
            path: Paths.STATUS_TABLE_MANAGE,
            name: `${Products.NAVIGATOR}:${Views.STATUS_TABLE_MANAGE}`,
            component: () => import(/* webpackChunkName: "manage-status-table-view" */'@/views/status/views/ManageStatusTable/ManageStatusTable.vue'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
            },
          },
          {
            path: `${Paths.STATUS_TABLE_EDIT}/:statusId`,
            name: `${Products.NAVIGATOR}:${Views.STATUS_TABLE_EDIT}`,
            component: () => import(/* webpackChunkName: "edit-status-entry-view" */'@/views/status/views/EditStatusEntry.vue'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
            },
            props: (route) => ({
              statusId: route.params.statusId,
            }),
          },
          {
            path: Paths.STATUS_TABLE_CREATE,
            name: `${Products.NAVIGATOR}:${Views.STATUS_TABLE_CREATE}`,
            component: () => import(/* webpackChunkName: "create-status-entry-view" */'@/views/status/views/CreateStatusEntry.vue'),
            meta: {
              requiredPermissions: ['navigator-collaboration-owner'],
            },
          },
        ],
      },
      {
        path: Paths.JURISDICTION_MANAGEMENT,
        name: `${Products.NAVIGATOR}:${Views.JURISDICTION_MANAGEMENT}`,
        component: () => import(/* webpackChunkName: "jurisdiction-management-view" */ '@/views/jurisdition-management/JurisdictionManagement.vue'),
        meta: {
          requiredPermissions: ['navigator-collaboration-owner'],
          icon: JurisdictionManagementIcon,
        },
      },
      {
        path: Paths.AUDIT_HISTORY,
        name: `${Products.NAVIGATOR}:${Views.AUDIT_HISTORY}`,
        component: () => import(/* webpackChunkName: "audit-hitory-view" */ '@/views/audit-history/AuditHistory.vue'),
        meta: {
          requiredPermissions: ['navigator-collaboration-owner'],
          icon: AuditHistoryIcon,
        },
      },
    ],
  },
];

export default navigatorRoutes;
