import {
  MutatingIndicatorType,
} from '@/composables/useMutatingIndicators/useMutatingIndicatorsTypes';

const getParsedEnv = (value: string | undefined): number | null => {
  if (!value) {
    return null;
  }

  const result = parseInt(value, 10);

  if (Number.isNaN(result)) {
    return null;
  }

  return result;
};

const getEnvFromType = (type: MutatingIndicatorType): string | undefined => {
  switch (type) {
    case 'global-definitions':
      return process.env.VUE_APP_MUTATING_GLOBAL_DEFINITION_INDICATOR_TIMEOUT;
    case 'local-definitions':
      return process.env.VUE_APP_MUTATING_LOCAL_DEFINITION_INDICATOR_TIMEOUT;
    case 'status':
      return process.env.VUE_APP_MUTATING_STATUS_INDICATOR_TIMEOUT;
    case 'legends':
      return process.env.VUE_APP_MUTATING_LEGEND_INDICATOR_TIMEOUT;
    default:
      return undefined;
  }
};

// Returned value is in seconds, but not in milliseconds
const getIndicatorTimeout = (type: MutatingIndicatorType): number => {
  const value = getEnvFromType(type);

  switch (type) {
    case 'global-definitions':
    case 'local-definitions':
    case 'status':
    case 'legends':
      return (getParsedEnv(value) ?? 300);

    default:
      return 300;
  }
};

export default getIndicatorTimeout;
