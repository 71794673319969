import { App } from 'vue';
import { Router } from 'vue-router';

import { Products } from '@/config/enums';
import { Paths } from '@/router/enums';
import { errorRouteNames } from '@/router/errorRoutes';
import store from '@/store';
import isInArray from '../utils/isInArray';

function checkPermissions(router: Router): void {
  const allProducts = Object.values(Products) as string[];

  router.beforeResolve((to) => {
    const goingToAuthPage = to.path === `/${Paths.AUTHENTICATION}`;
    let routeNameStart = '';

    if (typeof to.name === 'string') {
      ([routeNameStart] = to.name.split(':'));
    }

    if (goingToAuthPage || isInArray(routeNameStart, errorRouteNames)) {
      return true;
    }

    if (routeNameStart && allProducts.includes(routeNameStart)) {
      const userPermissions: string[] = store.getters['auth/userCollaborationPermissions'];
      const hasProductPermission = (
        userPermissions.find((p: string) => (
          p && p.includes(`${routeNameStart}-collaboration-`)))
      );

      if (!hasProductPermission) {
        throw Error('You do not have permission to view this page!');
      }

      return true;
    }

    throw Error('You do not have permission to view this page!');
  });
}

export default {
  install: (_app: App, options: { router: Router; }): void => {
    const { router } = options;

    checkPermissions(router);
  },
};
