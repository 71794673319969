import {
  computed,
  isRef,
  watchEffect,
} from 'vue';
import type { Ref } from 'vue';
import { MutatingIndicatorType } from './useMutatingIndicatorsTypes';
import useMutatingIndicators from './useMutatingIndicators';

const useMutatingIndicator = (
  type: MutatingIndicatorType | Ref<MutatingIndicatorType>,
  id: string | Ref<string>,
  updatedAt: Date | Ref<Date>,
) => {
  const store = useMutatingIndicators(type);

  const theId = computed<string>(() => (isRef(id) ? id.value : id));
  const theUpdatedAt = computed<Date>(() => (isRef(updatedAt) ? updatedAt.value : updatedAt));

  const isMutating = computed<boolean>(() => store.isMutating(theId.value, theUpdatedAt.value));
  const hasError = computed<boolean>(() => store.hasError(theId.value, theUpdatedAt.value));

  watchEffect(() => {
    if (!isMutating.value) {
      store.remove(theId.value);
    }
  });

  return {
    isMutating,
    hasError,
  };
};

export default useMutatingIndicator;
