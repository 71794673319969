import { RootState } from '@/store/state';
import { GetterTree } from 'vuex';
import { Snackbar } from './snackbar/snackbar.types';
import { State } from './state';

export enum AppGetters {
  APP__SELECTED_PRODUCT = 'APP__SELECTED_PRODUCT',
  APP__DARK_MODE = 'APP__DARK_MODE',
  APP__SNACKBAR = 'APP__SNACKBAR',
}

// getters types
export type Getters = {
  [AppGetters.APP__SELECTED_PRODUCT](state: State): string;
  [AppGetters.APP__DARK_MODE](state: State): boolean;
  [AppGetters.APP__SNACKBAR](state: State): Snackbar[];
};

// getters
export const getters: GetterTree<State, RootState> & Getters = {
  [AppGetters.APP__SELECTED_PRODUCT]: (state) => state.selectedProduct,
  [AppGetters.APP__DARK_MODE]: (state) => state.darkMode,
  [AppGetters.APP__SNACKBAR]: (state) => state.snackbars,
};
