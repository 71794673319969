import { Views } from '@/router/enums';
import { Products } from './enums';
import type { ElementOf } from '@/types/utilityTypes';

export const LOCAL_STORAGE_SELECTED_PRODUCT_KEY = 'ppp_selected_product';
export const LOCAL_STORAGE_SELECTED_LANGUAGE_KEY = 'ppp_language';
export const DEFAULT_PRODUCT = Products.NAVIGATOR;

export const routePagesInMenu = <const>[
  Views.DASHBOARD,
  Views.UPDATE_CYCLE_MANAGE,
  Views.CONTACTS,
  Views.AUDIT_HISTORY,
  Views.JURISDICTION_MANAGEMENT,
  Views.GLOBAL_DEFINITIONS,
  Views.LOCAL_DEFINITIONS,
  Views.LEGENDS,
  Views.STATUS_TABLE,
];

export const menuRouteLabels: Record<ElementOf<typeof routePagesInMenu>, string> = {
  [Views.DASHBOARD]: 'Dashboard',
  [Views.UPDATE_CYCLE_MANAGE]: 'Manage update cycle',
  [Views.CONTACTS]: 'Contacts',
  [Views.AUDIT_HISTORY]: 'Audit history',
  [Views.JURISDICTION_MANAGEMENT]: 'Jurisdiction Management',
  [Views.GLOBAL_DEFINITIONS]: 'Global definitions',
  [Views.LOCAL_DEFINITIONS]: 'Local definitions',
  [Views.LEGENDS]: 'Legends',
  [Views.STATUS_TABLE]: 'Status table',
};

export const sidebarWidths = {
  closed: '0px',
  opened: '50px',
  hovered: '250px',
};

export const tableCellsText = {
  publish: {
    scheduledDate: 'No scheduled date',
    owner: 'No owner',
  },
  jurisdictionManagement: {
    owner: 'No owner',
    jurisdiction: 'No jurisdiction',
  },
};
