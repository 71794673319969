import {
  computed, isRef,
  onBeforeUnmount,
  onMounted, Ref,
  WritableComputedRef,
} from 'vue';
import { useStore } from 'vuex';

import {
  MutatingIndicatorsStoreState,
  MutatingIndicatorStoreType,
} from '@/composables/useMutatingIndicators/useMutatingIndicatorsTypes';
import { LocalStorageGetters } from '@/store/modules/localStorage/getters';
import { LocalStorageMutations } from '@/store/modules/localStorage/mutations';

// Used to easily clear user's local storage when structure of data, stored in it will change
// In `usePersistent` call we'll just have to bump the version of data (sort of
// major update by semantic versioning) and user's store will be ignored.
const VERSION_KEY = '__version';

type Key<T extends string> = T | Ref<T>;
type Return<T> = WritableComputedRef<T | null>;
type ParsedStore<T> = { [VERSION_KEY]?: number, data?: T };
type StoreStructure<T> = { [VERSION_KEY]: number, data: T };

// eslint-disable-next-line max-len
function usePersistentStorage(key: Key<MutatingIndicatorStoreType>, version?: number): Return<MutatingIndicatorsStoreState>;
function usePersistentStorage<T = unknown>(key: Key<string>, version = 1): Return<T> {
  const store = useStore();

  const theKey = computed<string>(() => (isRef(key) ? key.value : key));

  const reactiveValue = computed<T | null>({
    get: () => {
      // eslint-disable-next-line max-len
      const string: string | null = store.getters[LocalStorageGetters.LOCAL_STORAGE_GET](theKey.value);
      if (!string) {
        return null;
      }

      const storedObject: ParsedStore<T> = JSON.parse(string);
      if (typeof storedObject !== 'object') {
        return null;
      }

      const storedVersion = storedObject[VERSION_KEY];
      if (storedVersion === undefined || storedVersion !== version) {
        return null;
      }

      return storedObject.data ?? null;
    },
    set: (v) => {
      if (v === null) {
        store.commit(LocalStorageMutations.LOCAL_STORAGE_SET, { key: theKey.value, value: null });
        return;
      }

      const objectToStore: StoreStructure<T> = { [VERSION_KEY]: version, data: v };
      const string = JSON.stringify(objectToStore);
      store.commit(LocalStorageMutations.LOCAL_STORAGE_SET, { key: theKey.value, value: string });
    },
  });

  const handleLocalStorageUpdate = () => {
    store.commit(LocalStorageMutations.LOCAL_STORAGE_REFRESH, { key: theKey.value });
  };

  onMounted(() => {
    window.addEventListener('storage', handleLocalStorageUpdate);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('storage', handleLocalStorageUpdate);
  });

  return reactiveValue;
}

export default usePersistentStorage;
