import { computed } from 'vue';
import { useStore } from 'vuex';
import type { RouteRecordRaw } from 'vue-router';
import type { Ref } from 'vue';

import { Products } from '@/config/enums';
import {
  getPermissionProducts, nonNullable, isInArray, getAllRoutes,
} from '@/utils';
import store from '@/store';

type ProductOption = {
  label: string;
  value: string;
  icon: Record<string, unknown> | null;
};

type UseUserAccessReturnType = {
  productsUserCanAccess: ProductOption[];
  routesUserCanAccess: RouteRecordRaw[];
  cannotAccessAnyProduct: Ref<boolean>;
  canAccessMoreThanOneProduct: Ref<boolean>;
  isDocumentOwner: Ref<boolean>;
  isOwner: Ref<boolean>;
};

const allValidProducts = Object.values(Products);

export const useUserCollabPermissions = (): string[] => {
  const currentStore: typeof store = useStore();

  return currentStore.getters['auth/userCollaborationPermissions'];
};

const getValidProductsUserCanAccess = (usersProducts: string[]): ProductOption[] => usersProducts
  .map((product) => {
    const validProduct = isInArray(product, allValidProducts);

    return validProduct
      ? {
        label: product as string,
        value: product as string,
        icon: null,
      }
      : null;
  })
  .filter(nonNullable);

const getProductsUserCanAccess = (userCollabPermissions: string[]): ProductOption[] => {
  const userProducts: string[] = getPermissionProducts(userCollabPermissions);

  return getValidProductsUserCanAccess(userProducts);
};

const getRoutesUserCanAccess = (userCollabPermissions: string[]): RouteRecordRaw[] => {
  const allAvailableRoutes: RouteRecordRaw[] = getAllRoutes();

  return allAvailableRoutes.filter((routeRecord) => {
    if (routeRecord.meta?.requiredPermissions) {
      return (routeRecord.meta.requiredPermissions as string[]).every(
        (routePermission) => userCollabPermissions.some(
          (permissionString: string) => permissionString.includes(routePermission),
        ),
      );
    }

    return false;
  });
};

const getIsDocumentOwner = (
  product: Ref<string>,
  location: Ref<string> | undefined,
  userCollabPermissions: string[],
): boolean => {
  if (!location?.value) return false;

  const permission = `|${product.value}-collaboration-owner:${location.value}`;
  const isOwnerD = userCollabPermissions.find((item) => item.endsWith(permission));

  return !!isOwnerD;
};

const getIsOwner = (product: Ref<string>, userCollabPermissions: string[]): boolean => {
  const hasOwnerPermission = userCollabPermissions.find(
    (item: string) => item.includes(product.value) && item.includes('owner'),
  );
  return !!hasOwnerPermission;
};

export default function useUserAccess(
  product: Ref<string>,
  location?: Ref<string>,
): UseUserAccessReturnType {
  const userCollabPermissions = useUserCollabPermissions();

  const productsUserCanAccess: ProductOption[] = getProductsUserCanAccess(userCollabPermissions);
  const routesUserCanAccess: RouteRecordRaw[] = getRoutesUserCanAccess(userCollabPermissions);
  const isDocumentOwner: Ref<boolean> = computed(
    () => getIsDocumentOwner(product, location, userCollabPermissions),
  );
  const isOwner: Ref<boolean> = computed(() => getIsOwner(product, userCollabPermissions));

  const canAccessMoreThanOneProduct: Ref<boolean> = computed(
    (): boolean => productsUserCanAccess.length > 1,
  );
  const cannotAccessAnyProduct: Ref<boolean> = computed(
    (): boolean => productsUserCanAccess.length === 0,
  );

  return {
    productsUserCanAccess,
    routesUserCanAccess,
    cannotAccessAnyProduct,
    canAccessMoreThanOneProduct,
    isDocumentOwner,
    isOwner,
  };
}
