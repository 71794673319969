import {
  ApolloClient,
  concat,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client/core';
import type { NormalizedCacheObject } from '@apollo/client/core';

import createAuthMiddleware from './createAuthMiddleware';

export type ClientOptions = {
  graphqlEndpoint: string;
};

function createClient(clientOptions: ClientOptions): ApolloClient<NormalizedCacheObject> {
  const cache = new InMemoryCache();
  const httpLink = createHttpLink({
    uri: clientOptions.graphqlEndpoint,
  });
  const authMiddleware = createAuthMiddleware();

  return new ApolloClient({
    cache,
    link: concat(authMiddleware, httpLink),
  });
}

export default createClient;
