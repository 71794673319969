import type { MutationTree } from 'vuex';
import type { Snackbar } from './snackbar/snackbar.types';
import type { State } from './state';
import { nanoid } from 'nanoid';

// Mutation enums
export enum AppMutations {
  APP__SET_SELECTED_PRODUCT = 'APP__SET_SELECTED_PRODUCT',
  APP__SET_DARK_MODE = 'APP__SET_DARK_MODE',
  APP__ADD_SNACKBAR = 'APP__ADD_SNACKBAR',
  APP__REMOVE_SNACKBAR = 'APP__REMOVE_SNACKBAR',
}

// Mutation contracts
export type Mutations<S = State, P = unknown> = {
  [AppMutations.APP__SET_SELECTED_PRODUCT](state: S, payload: P): void;
  [AppMutations.APP__SET_DARK_MODE](state: S, payload: P): void;
  [AppMutations.APP__ADD_SNACKBAR](state: S, payload: P): void;
  [AppMutations.APP__REMOVE_SNACKBAR](state: S, payload: P): void;
};

// Define mutations
export const mutations: MutationTree<State> & Mutations = {
  [AppMutations.APP__SET_SELECTED_PRODUCT](state: State, payload: string) {
    state.selectedProduct = payload;
  },
  [AppMutations.APP__SET_DARK_MODE](state: State, payload: boolean) {
    state.darkMode = payload;
  },
  [AppMutations.APP__ADD_SNACKBAR](state: State, payload: Snackbar) {
    const newSnackbar = {
      id: nanoid(),
      messageContent: payload.messageContent,
      classes: payload.classes || '',
      processes: payload.processes || [],
      timeout: payload.timeout,
      icon: payload.icon || {},
      type: payload.type,
    };
    state.snackbars = [...state.snackbars, newSnackbar];
  },
  [AppMutations.APP__REMOVE_SNACKBAR](state: State, payload: string) {
    state.snackbars = state.snackbars.filter((snackbar) => snackbar.id !== payload);
  },
};
