import { RootState } from '@/store/state';
import {
  CommitOptions,
  DispatchOptions, Module, Store as VuexStore,
} from 'vuex';

import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

import type { Actions } from './actions';
import type { Getters } from './getters';
import type { Mutations } from './mutations';
import type { State } from './state';

import { LOCAL_STORAGE_SELECTED_PRODUCT_KEY } from '@/config/constants';

const getSelectedProductFromLocalStorage = (): string | null => (
  localStorage.getItem(LOCAL_STORAGE_SELECTED_PRODUCT_KEY)
);

const state: State = {
  selectedProduct: getSelectedProductFromLocalStorage() || '',
  darkMode: true,
  snackbars: [],
};

export type Store<S = State> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions,
  ): ReturnType<Actions[K]>;
};

export const AppStore: Module<State, RootState> = {
  state,
  mutations,
  actions,
  getters,
};
