const handleLogoutClick = async (store: any): Promise<void> => {
  try {
    const logoutAction = () => store.dispatch('auth/logout');
    await logoutAction();
  } catch (err) {
    // TODO: handle error - notify user, log it etc.
    console.error({ err });
  }
};

export default handleLogoutClick;
