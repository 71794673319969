/* eslint-disable no-console */
import { register as registerServiceWorker } from 'register-service-worker';

class Worker {
  private isDownloadingUpdates = false;

  private hasPendingUpdates = false;

  private registration: ServiceWorkerRegistration | null = null;

  async checkForUpdates() {
    await this.registration?.update();
  }

  reloadIfHasUpdates() {
    if (this.hasPendingUpdates) {
      window.location.reload();
    }
  }

  skipWaiting() {
    this.registration?.waiting?.postMessage({ action: 'skipWaiting' });
  }

  register() {
    if (process.env.NODE_ENV === 'production') {
      registerServiceWorker(`${process.env.BASE_URL}service-worker.js`, {
        ready: () => {
          this.hasPendingUpdates = false;
        },
        registered: (_registration) => {
          this.registration = _registration;
        },
        updatefound: () => {
          this.isDownloadingUpdates = true;
        },
        updated: (_registration) => {
          this.registration = _registration;
          this.skipWaiting();

          // Need to change the flag only when update comes in an active user session
          // but not when user just came to the site.
          //
          // In case when user just entered and service-worker was updated while he has offline
          // then just `updated` will be called.
          //
          // In case when update arrived when user is using application then
          // `updatefound` and then `updated` will be called
          if (this.isDownloadingUpdates) {
            this.isDownloadingUpdates = false;
            this.hasPendingUpdates = true;
          }

          this.reloadIfHasUpdates();
        },
      });
    }
  }
}

const worker = new Worker();

export default worker;
