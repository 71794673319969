import { MutationTree } from 'vuex';

import type { State } from './state';
import { getLocalStorageKey } from './utils';

export enum LocalStorageMutations {
  LOCAL_STORAGE_SET = 'LOCAL_STORAGE_SET',
  LOCAL_STORAGE_REFRESH = 'LOCAL_STORAGE_REFRESH',
}

export type Mutations<S = State, P = unknown> = {
  [LocalStorageMutations.LOCAL_STORAGE_SET](state: S, payload: P): void;
  [LocalStorageMutations.LOCAL_STORAGE_REFRESH](state: S, payload: P): void;
}

export const mutations: MutationTree<State> & Mutations = {
  // eslint-disable-next-line max-len
  [LocalStorageMutations.LOCAL_STORAGE_SET](state: State, payload: { key: string, value: string | null }) {
    if (payload.value === null) {
      localStorage.removeItem(getLocalStorageKey(payload.key));
      state[payload.key] = payload.value;
      return;
    }

    localStorage.setItem(getLocalStorageKey(payload.key), payload.value);
    state[payload.key] = payload.value;
  },

  [LocalStorageMutations.LOCAL_STORAGE_REFRESH](state: State, payload: { key: string }) {
    state[payload.key] = localStorage.getItem(getLocalStorageKey(payload.key));
  },
};
