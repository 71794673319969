/**
 * Given an array of permission strings, return an array of unique strings corresponding
 * to the name of the product
 * @param productPermissions {string[]} Array of permission strings
 * @returns {string[]}
 */
export default function getPermissionProducts(productPermissions: string[]): string[] {
  const usersProducts: string[] = [];

  productPermissions.forEach((productPermission) => {
    const [, permissionReference] = productPermission.split('|');

    if (permissionReference) {
      const [product] = permissionReference.split('-');

      if (product) usersProducts.push(product);
    }
  });

  return [...new Set(usersProducts)];
}
