import { computed, isRef, Ref } from 'vue';
import { useUserCollabPermissions } from '@/composables/useUserAccess';

const formatLocation = (location: string): string => {
  if (location) {
    return location.toLowerCase().replace(/[^\w\s]/gi, '').replace(/ /g, '-');
  }
  return '';
};

export const isOwnerOfJurisdiction = (
  permissions: Array<string>,
  product: string,
  location: string,
): boolean => {
  if (!location) {
    return false;
  }

  const formattedLocation = formatLocation(location);
  const permission = `|${product}-collaboration-owner:${formattedLocation}`;
  const isOwner = permissions.find((item) => item.endsWith(permission));
  return !!isOwner;
};

const useOwnerOfJurisdiction = (
  product: Ref<string> | string,
  location: Ref<string> | string,
): Ref<boolean> => {
  // store is used instead of useStore(), as this function needs to be called inside filter method
  // in that case useStore() returns undefined
  const userCollabPermissions = useUserCollabPermissions();

  const theProduct = computed(() => {
    if (isRef(product)) {
      return product.value;
    }

    return product;
  });

  const theLocation = computed(() => {
    if (isRef(location)) {
      return location.value;
    }

    return location;
  });

  return computed(() => isOwnerOfJurisdiction(
    userCollabPermissions,
    theProduct.value,
    theLocation.value,
  ));
};

export default useOwnerOfJurisdiction;
