import cryptoRoutes from '@/products/crypto/routes';
import navigatorRoutes from '@/products/navigator/routes';
import xbtechRoutes from '@/products/xbtech/routes';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import updateTabTitle from '../utils/updateTabTitle';
import { Paths, Views } from './enums';
import errorRoutes from './errorRoutes';

export const routes: Array<RouteRecordRaw> = [
  ...cryptoRoutes,
  ...navigatorRoutes,
  ...xbtechRoutes,
  {
    path: `/${Paths.AUTHENTICATION}`,
    name: Views.AUTHENTICATION,
    component: () => import(/* webpackChunkName: "authentication-page" */ '@/views/authentication/Authentication.vue'),
  },
  {
    path: `/${Paths.EMAIL_NOT_VERIFIED}`,
    name: Views.EMAIL_NOT_VERIFIED,
    component: () => import(/* webpackChunkName: "authentication-page" */'@/views/authentication/Authentication.vue'),
    meta: {
      title: 'Verifying Email',
    },
  },
  ...errorRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.onError((error, to) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = to.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

router.beforeEach((to) => {
  const stringRoute = to?.name?.toString();
  const arrRoute = stringRoute?.split(':');
  let product: string;
  let page: string;

  if (!arrRoute) return;

  if (arrRoute.length > 1) {
    page = arrRoute[1].split(/(?=[A-Z])/).join(' ');
    product = arrRoute?.[0];
  } else {
    // Had this case if the user not yet logged in, so he will have no selected product
    page = arrRoute[0].split(/(?=[A-Z])/).join(' ');
    product = 'none';
  }

  updateTabTitle(page, product);
});

export default router;
