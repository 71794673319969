import { Products } from '@/config/enums';
import { Paths, Views } from '@/router/enums';
import store from '@/store/index';
import { App } from 'vue';
import { Router } from 'vue-router';

// TODO: move to config file?
const LANG_KEY = 'ppp_language';

function toAuthPage() {
  return {
    name: Views.AUTHENTICATION,
  };
}

function toHomePage() {
  const language = localStorage.getItem(LANG_KEY) ?? 'en';

  return {
    name: Products.NAVIGATOR,
    params: { language },
  };
}

async function renewSession(): Promise<boolean> {
  let renewed = false;

  try {
    renewed = await store.dispatch('auth/renewSession');
  } catch {
    renewed = false;
  }

  return renewed;
}

/**
 * Update the page title on each route change and check then
 * authentication status of the user
 * @param Router Vue Router instance
 */
function setupAuthentication(router: Router) {
  router.beforeEach(async (to, from) => {
    if (to.path === Paths.ROOT) {
      return toAuthPage();
    }

    const goingToAuthPage = to.path === `/${Paths.AUTHENTICATION}`;

    if (goingToAuthPage) {
      const authenticated: boolean = store.getters['auth/authenticated'];

      if (authenticated) {
        return toHomePage();
      }

      if (to.hash) {
        try {
          // attempt to authenticate the user
          await store.dispatch('auth/handleAuthentication');

          // authentication was successful; send them to the home page
          return toHomePage();
        } catch {
          return true;
        }
      }
    }

    try {
      const authenticated: string | null = await store.dispatch('auth/setIsAuthenticated');
      const token: string = store.getters['auth/accessToken'];

      if (token === '' && (!from.name || authenticated)) {
        // access token is not set; renew the session
        const renewSuccess = await renewSession();

        if (renewSuccess) {
          return (goingToAuthPage) ? toHomePage() : true;
        }

        return (goingToAuthPage) ? true : toAuthPage();
      }

      if (authenticated) {
        return (goingToAuthPage) ? toHomePage() : true;
      }

      if (!authenticated) {
        return (goingToAuthPage) ? true : toAuthPage();
      }

      return true;
    } catch {
      return (goingToAuthPage) ? true : toAuthPage();
    }
  });
}

/**
 * @param _app Vue instance
 * @param options Object
 * {
 *   router: Vue Router instance
 * }
 */
export default {
  install: (_app: App, options: { router: Router; }): void => {
    const { router } = options;

    if (!router) throw Error('Vue Router instance required for authentication');

    setupAuthentication(router);
  },
};
