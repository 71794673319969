import { some as lodashSome } from 'lodash-es';
import { RouteRecordRaw } from 'vue-router';
import { routes } from '@/router';

export default function getAllRoutes(): RouteRecordRaw[] {
  const allRouteRecords: RouteRecordRaw[] = [];

  const recursiveFindAllRoutes = (routeRecord: RouteRecordRaw): void => {
    const alreadyHasRecord = lodashSome(allRouteRecords, ['name', routeRecord.name]);

    if (!alreadyHasRecord) {
      allRouteRecords.push(routeRecord);
    }

    if (routeRecord.children?.length) {
      routeRecord.children.forEach(recursiveFindAllRoutes);
    }
  };

  routes.forEach(recursiveFindAllRoutes);

  return allRouteRecords;
}
