export enum Products {
  CRYPTO = 'crypto',
  NAVIGATOR = 'navigator',
  XBTECH = 'xbtech',
}

export enum ProgressStatuses {
  ACTIVE_COUNSEL = 'Active Counsel',
  PREP = 'Preparation',
  ACTIVE_SIMMONS = 'Active Simmons',
  ABANDONED = 'Abandoned',
  PUBLISHED = 'Published',
  APPROVED = 'Approved'
}

export enum ReviewGuidanceProgressStatusesForUpdate {
  ACTIVE_COUNSEL,
  ACTIVE_SIMMONS,
  PREP,
}
