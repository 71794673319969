// CKEditor Plugins
import Autosave from '@ckeditor/ckeditor5-autosave/src/autosave';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import Link from '@ckeditor/ckeditor5-link/src/link';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';


class CKEditorCS extends ClassicEditor {}

// Plugins to include in the build.
CKEditorCS.builtinPlugins = [
  Autosave,
  Bold,
  ItalicPlugin,
  UnderlinePlugin,
  StrikethroughPlugin,
  Essentials,
  Highlight,
  ListPlugin,
  Link,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  WordCount,
];

export default CKEditorCS;
