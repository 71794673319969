import { Views } from '@/router/enums';
import store from '@/store';
import { App } from 'vue';
import { Router } from 'vue-router';

function checkPermissions(router: Router): void {
  router.beforeResolve(async (to, _from, next) => {
    try {
      const requiredPermissions: string[] = to.meta.requiredPermissions as string[];

      // If no required permissions defined then allow navigation
      if (!requiredPermissions?.length) return next();

      // If user not authenticated then redirect to unauthorised
      if (!store.getters['auth/authenticated']) throw Error('User not authenticated');

      const userCollaborationPermissions: string = await store.getters[
        'auth/userCollaborationPermissions'
      ].join('');
      const userHasRequiredPermissions = requiredPermissions.every(
        (permission) => userCollaborationPermissions.includes(permission),
      );

      // If user does not have required permissions then redirect to unauthorised
      if (!userHasRequiredPermissions) throw Error('User is unauthorised to view this page');

      return next();
    } catch (e) {
      return next({
        name: Views.UNAUTHORISED,
      });
    }
  });
}

export default {
  install: (_app: App, options: { router: Router; }): void => {
    const { router } = options;

    checkPermissions(router);
  },
};
