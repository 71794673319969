import { createStore } from 'vuex';
import { auth, app, localStorage } from './modules';
import type { RootState } from './state';

const debug = process.env.NODE_ENV !== 'production';
const state: RootState = {
  appName: 'Product Platform Portal',
};

const store = createStore({
  state,
  modules: {
    auth,
    app,
    localStorage,
  },
  actions: {
    CLEAR_SESSION_DATA() {
      // TODO: nothing
    },
  },
  strict: debug,
});

export default store;
