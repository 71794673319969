import { ref } from 'vue';
import { LocationQueryRaw, useRoute, useRouter } from 'vue-router';

export type GenericFilters = Record<string, unknown>;

const useRouteQuery = <
  TFilters extends GenericFilters = GenericFilters,
> (): {
  get: () => TFilters & GenericFilters,
  update: (filters: TFilters) => Promise<void>,
  remove: (fields: Array<keyof TFilters>) => Promise<void>,
} => {
  const route = useRoute();
  const router = useRouter();
  const query = ref<LocationQueryRaw>(route.query);

  const get = () => {
    const routeQuery: TFilters = {} as TFilters;
    query.value = route.query;

    if (query.value) {
      Object.entries(query.value).forEach(([key, value]) => {
        if (typeof value !== 'string') {
          return;
        }

        const k = key as keyof TFilters;
        routeQuery[k] = JSON.parse(value);
      });
    }

    return routeQuery;
  };

  // NEED to use async await on calling to update URL state correctly on multiple calls
  const update = async (
    filters: TFilters,
  ) => {
    query.value = route.query;

    const formedQuery: LocationQueryRaw = {
      ...query.value,
    };

    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        const isArray = Array.isArray(value);

        if (isArray && value.length > 0) {
          formedQuery[key] = JSON.stringify(value);
        }

        if (!isArray) {
          formedQuery[key] = JSON.stringify(value);
        }
      }
    });

    await router.push({
      query: formedQuery,
    });
  };

  // NEED to use async await on calling to update URL state correctly on multiple calls
  const remove = async (fields: Array<keyof TFilters>) => {
    query.value = route.query;
    const updatedQuery: LocationQueryRaw = {
      ...query.value,
    };

    fields.forEach((field: keyof TFilters) => {
      delete updatedQuery[field as string];
    });

    await router.push({
      query: updatedQuery,
    });
  };

  return {
    get,
    update,
    remove,
  };
};

export default useRouteQuery;
