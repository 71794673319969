import * as Sentry from '@sentry/browser';
import type { Event, BrowserOptions } from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import hasUserAgent from '../utils/hasUserAgent';

const setupSentry = () => {
  try {
    const dsn = process.env.VUE_APP_SENTRY_DSN;
    const release = process.env.VUE_APP_BUILD;
    const environment = process.env.VUE_APP_ENV;
    let sampleRate = 1;

    const ENV_SAMPLE_RATE = process.env.VUE_APP_SENTRY_SAMPLE_RATE;
    if (ENV_SAMPLE_RATE && ENV_SAMPLE_RATE !== '%VUE_APP_SENTRY_SAMPLE_RATE%') {
      sampleRate = parseFloat(ENV_SAMPLE_RATE) || sampleRate;
    }

    const sentryOptions: BrowserOptions = {
      attachStacktrace: true,
      autoSessionTracking: true,
      debug: process.env.VUE_APP_ENV !== 'production',
      tracesSampleRate: sampleRate,
      beforeSend(event: Event) {
        if (hasUserAgent(event, 'HeadlessChrome')) return null; // Do not track events from HeadlessChrome as likely automated / bot user

        return event;
      },
      // Prevent console breadcrumbs from being sent
      // As with CKEditor Errors, it would cause too big of a payload, which would result
      // in a 413 error when trying to send over the sentry log
      beforeBreadcrumb(breadcrumb) {
        if (breadcrumb.category === 'console') {
          return null;
        }
        return breadcrumb;
      },
    };

    if (!dsn) return;

    Sentry.init({
      dsn,
      release,
      environment,
      integrations: [
        new BrowserTracing(),
        new CaptureConsole({
          levels: ['error'],
        }),
      ],
      ...sentryOptions,
    });
  } catch (err) {
    console.error(`There was an issue setting up sentry: ${err}`);
  }
};

export default setupSentry;
