import router from '@/router';
import { Views } from '@/router/enums';
import store from '@/store';
import { AppMutations } from '@/store/modules/app/mutations';

const navigateToDashboardProcessing = () => {
  // Display snackbar message
  store.commit(AppMutations.APP__ADD_SNACKBAR, {
    messageContent: 'Document is processing. Please try again later.',
    timeout: 3,
    type: 'warning',
  });

  // Navigate user to dashboard
  const currentRoute = router.currentRoute.value.name as string;
  const [selectedProduct] = currentRoute.split(':');
  router.push({
    name: `${selectedProduct}:${Views.DASHBOARD}`,
  });
};

export default navigateToDashboardProcessing;
