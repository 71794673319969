import {
  differenceInSeconds,
  formatISO,
  isSameSecond,
  parseISO,
} from 'date-fns';
import { computed, isRef, Ref } from 'vue';

import usePersistentStorage from '@/composables/usePersistentStorage';

import {
  MutatingIndicatorStoreType,
  MutatingIndicatorType,
} from './useMutatingIndicatorsTypes';
import getIndicatorTimeout from './utils/getIndicatorTimeout';

const useMutatingIndicators = (type: MutatingIndicatorType | Ref<MutatingIndicatorType>) => {
  const theType = computed(() => (isRef(type) ? type.value : type));
  const storageKey = computed<MutatingIndicatorStoreType>(() => `mutating-${theType.value}`);
  const state = usePersistentStorage(storageKey, 1);

  const set = (id: string, lastUpdatedAt: Date, localUpdatedAt: Date) => {
    if (!state.value) {
      state.value = {};
    }

    state.value = {
      ...state.value,
      [id]: {
        lastUpdatedAt: formatISO(lastUpdatedAt),
        localUpdatedAt: formatISO(localUpdatedAt),
      },
    };
  };

  const remove = (id: string) => {
    if (!state.value) {
      return;
    }

    const idInState = id in state.value;
    if (!idInState) {
      return;
    }

    const newState = { ...state.value };
    delete newState[id];

    state.value = newState;
  };

  const privateIsMutating = (id: string, updatedAt: Date): boolean => {
    const lastUpdatedAtString = state.value?.[id]?.lastUpdatedAt;
    if (!lastUpdatedAtString) {
      return false;
    }

    const lastUpdatedAt = parseISO(lastUpdatedAtString);
    return isSameSecond(lastUpdatedAt, updatedAt);
  };

  const hasError = (id: string, updatedAt: Date): boolean => {
    const localUpdatedAtString = state.value?.[id]?.localUpdatedAt;
    if (!localUpdatedAtString) {
      return false;
    }

    if (!privateIsMutating(id, updatedAt)) {
      return false;
    }

    const localUpdatedAt = parseISO(localUpdatedAtString);
    const indicatorTimeout = getIndicatorTimeout(theType.value);

    const now = new Date();
    return differenceInSeconds(now, localUpdatedAt) >= indicatorTimeout;
  };

  // `isMutating` can't be true when `hasError` is true
  const isMutating = (id: string, updatedAt: Date): boolean => {
    if (hasError(id, updatedAt)) {
      return false;
    }

    return privateIsMutating(id, updatedAt);
  };

  return {
    state,
    set,
    remove,
    isMutating,
    hasError,
  };
};

export default useMutatingIndicators;
