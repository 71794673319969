import { useStore } from 'vuex';
import { computed } from 'vue';
import type { ComputedRef } from 'vue';

import { AppGetters } from '@/store/modules/app/getters';
import { DEFAULT_PRODUCT } from '@/config/constants';

export default function useSelectedProduct(): { selectedProduct: ComputedRef<string>; } {
  const store = useStore();

  const selectedProduct = computed(
    (): string => {
      const storeProduct = store.getters[AppGetters.APP__SELECTED_PRODUCT];
      return storeProduct || DEFAULT_PRODUCT;
    },
  );
  return {
    selectedProduct,
  };
}
