export enum Paths {
  CRYPTO = 'crypto',
  NAVIGATOR = 'navigator',
  XBTECH = 'xbtech',
  DOCUMENT = 'document',
  DASHBOARD = 'dashboard',
  AUDIT_HISTORY = 'audit-history',
  JURISDICTION_MANAGEMENT = 'jurisdiction-management',
  GLOBAL_DEFINITIONS = 'global-definitions',
  GLOBAL_DEFINITIONS_LIST = 'list',
  GLOBAL_DEFINITIONS_CREATE = 'create',
  GLOBAL_DEFINITIONS_EDIT = 'edit',
  LOCAL_DEFINITIONS = 'local-definitions',
  LOCAL_DEFINITIONS_LIST = 'list',
  LOCAL_DEFINITIONS_CREATE = 'create',
  CONTACTS = 'contacts',
  LEGENDS = 'legends',
  LEGENDS_LIST = 'list',
  LEGENDS_CREATE = 'create',
  STATUS_TABLE = 'status-table',
  STATUS_TABLE_CREATE = 'create',
  STATUS_TABLE_EDIT = 'edit',
  STATUS_TABLE_MANAGE = 'manage',
  AUTHENTICATION = 'auth',
  ROOT = '/',
  PAGE_NOT_FOUND = ':pathMatch(.*)*', // thepath should be 'page_not_found'
  UNAUTHORISED = 'unauthorised',
  EMAIL_NOT_VERIFIED = 'email-verified',
  UNHANDLED = 'error',
  UPDATE_CYCLE = 'update-cycle',
  UPDATE_CYCLE_PUBLISH = 'publish',
  UPDATE_CYCLE_MANAGE = 'manage',
  UPDATE_CYCLE_CREATE = 'create',
  UPDATE_CYCLE_EDIT = 'edit',
  VERSION = 'version',
}

export enum Views {
  CRYPTO = 'Crypto',
  NAVIGATOR = 'Navigator',
  XBTECH = 'XbTech',
  DASHBOARD = 'Dashboard',
  DOCUMENT = 'Document',
  DOCUMENT_VERSION = 'DocumentVersion',
  AUDIT_HISTORY = 'AuditHistory',
  JURISDICTION_MANAGEMENT = 'JurisdictionManagement',
  GLOBAL_DEFINITIONS = 'GlobalDefinitions',
  GLOBAL_DEFINITIONS_LIST = 'GlobalDefinitionsList',
  GLOBAL_DEFINITIONS_CREATE = 'GlobalDefinitionsCreate',
  GLOBAL_DEFINITIONS_EDIT = 'GlobalDefinitionsEdit',
  LOCAL_DEFINITIONS = 'LocalDefinitions',
  LOCAL_DEFINITIONS_LIST = 'LocalDefinitionsList',
  LOCAL_DEFINITIONS_CREATE = 'LocalDefinitionsCreate',
  CONTACTS = 'Contacts',
  LEGENDS = 'Legends',
  LEGENDS_LIST = 'LegendsList',
  LEGENDS_CREATE = 'LegendsCreate',
  STATUS_TABLE = 'StatusTable',
  STATUS_TABLE_MANAGE = 'ManageStatusTable',
  STATUS_TABLE_EDIT = 'EditStatusEntry',
  STATUS_TABLE_CREATE = 'CreateStatusEntry',
  AUTHENTICATION = 'AuthenticationPage',
  PAGE_NOT_FOUND = 'PageNotFound',
  UNAUTHORISED = 'UnauthorisedPage',
  EMAIL_NOT_VERIFIED = 'AuthVerifyEmailCallback',
  UNHANDLED = 'UnhandledServerErrorPage',
  UPDATE_CYCLE = 'UpdateCycle',
  UPDATE_CYCLE_PUBLISH = 'UpdateCyclePublish',
  UPDATE_CYCLE_MANAGE = 'ManageUpdateCycle',
  UPDATE_CYCLE_CREATE = 'CreateUpdateCycle',
  UPDATE_CYCLE_EDIT = 'EditUpdateCycle',
}
