import { AppMutations } from '@/store/modules/app/mutations';
import { useStore } from 'vuex';
import { ProcessObjectType } from '../store/modules/app/snackbar/snackbar.types';

interface Payload {
  messageContent: string,
  timeout?: number,
  classes?: string[],
  icon?: Record<string, unknown>,
  processes?: ProcessObjectType[],
}

interface PayloadWithType extends Payload {
  type: string,
}

const useSnackbar = () => {
  const store = useStore();

  const addSnackbar = (payload: PayloadWithType) => {
    store.commit(AppMutations.APP__ADD_SNACKBAR, payload);
  };

  return {
    success: (payload: Payload) => {
      addSnackbar({ ...payload, type: 'success' });
    },
    danger: (payload: Payload) => {
      addSnackbar({ ...payload, type: 'danger' });
    },
    info: (payload: Payload) => {
      addSnackbar({ ...payload, type: 'info' });
    },
    warning: (payload: Payload) => {
      addSnackbar({ ...payload, type: 'warning' });
    },
  };
};

export default useSnackbar;
