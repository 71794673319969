import { getAllClients } from '@/apollo';
import authPlugin from '@/plugins/authPlugin';
import permissionsPlugin from '@/plugins/permissionsPlugin';
import productAccessPlugin from '@/plugins/productAccessPlugin';
import serviceWorkerUpdatePlugin from '@/plugins/serviceWorkerUpdatePlugin';
import { ApolloClients } from '@vue/apollo-composable';
import {
  createApp, h, provide,
} from 'vue';
import App from './App.vue';
import ErrorHandling from './errorHandling';
import router from './router';
import setupSentry from './sentry';
import store from './store';
import worker from './worker';

worker.register();
ErrorHandling.init();

const setupApollo = (): void => {
  const apolloClients = getAllClients();
  const hasAtLeastOneClient = (): boolean => (Object.keys(apolloClients).length > 0);

  if (hasAtLeastOneClient()) {
    provide(ApolloClients, apolloClients);
  }
};

createApp({
  setup() {
    setupApollo();
    setupSentry();
  },
  render: () => h(App),
})
  .use(router)
  .use(store)
  .use(authPlugin, { router })
  .use(permissionsPlugin, { router })
  .use(productAccessPlugin, { router })
  .use(serviceWorkerUpdatePlugin, { router })
  .mount('#app');
