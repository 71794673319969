import useSelectedProduct from '../composables/useSelectedProduct';

const updateTabTitle = (page: string, _product?: string) => {
  let product = _product;

  if (!_product) {
    const { selectedProduct } = useSelectedProduct();
    product = selectedProduct.value;
  }

  // Need to handle if the user not yet logged
  if (_product === 'none') {
    product = '';
  } else {
    product = `- ${product}`;
  }

  document.title = `${page} ${product} - Product Platform Portal`;
};

export default updateTabTitle;
