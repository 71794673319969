import router from '@/router';
import store from '@/store';
import {
  ApolloLink,
} from '@apollo/client/core';
import { computed } from 'vue';

export default function createAuthMiddleware(): ApolloLink {
  const authToken = computed((): string => store.getters['auth/token']);

  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = { } }) => ({
      headers: {
        ...headers,
        'portal-policy': router.currentRoute.value.name,
        authorization: authToken.value ? `Bearer ${authToken.value}` : '',
      },
    }));

    return forward(operation);
  });
}
